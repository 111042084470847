import React from "react" 
import Wrapper from "../components/wrapper";
import { Link }from "gatsby"
import SectionTitle from "../components/sectionTitle";
import jobs from "../data/jobs.json"

const Jobs = ({location}) => {
    return (
        <Wrapper
            customTitle={"Job opportunities at Appleseeds in Subang Jaya, Selangor, Malaysia"} 
            customDescription={"If you heard your calling for saving the world from bad websites, you have come to the right place. Join us on this mission to create great work together."} 
            customKeyword={"web design malaysia, website designing kl, webpage designer, creative website design, website design company, web page design, website agency, creative agency, hotel website design"} 
            customOgImage={"/cover-jobs.jpg"}
            slug={location.pathname}
            >
            <section className="bg-ready">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName="lg:w-3/4"
                        alignCenter={true}
                        title={['Come and join us, ', <br className="hidden lg:block"/>, 'and thrive as one.']}
                        minititle=""
                        type="h1"
                        invert={true}
                        childrenClassName="lg:w-2/3"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            If you would like to be part of a world where people live and breath design and development, where creating awesome digital products gives you more satisfaction than watching the most satisfying video on the Internet, you have come to the right place.
                        </p>
                    </SectionTitle>
                </div>
            </section>

            <section className="bg-community">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={false}
                        title={['It’s always about our people, ', <br/>, 'and empowering them to strive.']}
                        minititle="Our beliefs"
                        type="h3"
                        invert={true}
                        childrenClassName="lg:w-1/2"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            People are the biggest asset of our company. We believe that happy people create better work. That’s why we invest in them by providing training, guidance, support, and flexibility while constantly challenging them to improve themselves in every way.
                        </p>
                    </SectionTitle>
                </div>
            </section>

            <section className="bg-about-workplace">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={false}
                        title={['A space that is so fun, ', <br/>, 'it’s almost like a playground.']}
                        minititle="Our workspace"
                        type="h3"
                        invert={true}
                        childrenClassName="lg:w-3/5"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            We don’t believe in the conventional ‘9 to 5’ or cubicle offices. We believe that our work environment plays an important role in sparking new ideas, promoting collaboration, and increasing productivity. We spent years looking for the right place, and now we are based at WORQ — a vibrant and exciting co-working space where like-minded people come together not just to work but also to learn, grow, and play.
                        </p>
                        <p className="mt-8 inline-block btn btn-light">
                            <a href="https://worq.space" rel="noreferrer" target="_blank">Find out more about WORQ</a>
                        </p>
                    </SectionTitle>
                </div>
            </section>

            <section className="bg-brand-dark-blue py-14 md:py-28">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName="mb-16 md:mb-20"
                        alignCenter={false}
                        title="Positions"
                        minititle=""
                        type="h2"
                        invert={true}
                        childrenClassName="md:w-3/4"
                        buttonText=""
                        buttonType=""
                        buttonLink=""
                        >
                        <p>
                            If you like what you see and would like to be part of us, check out the following opening job positions. We certainly do hope to hear from you!
                        </p>
                    </SectionTitle>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-10 md:gap-12">
                        {jobs.map((job, index)=>{
                            return(
                                <div key={index} className="col-span-1">
                                    <h4 className="font-normal text-white mb-3">{job.title}</h4>
                                    <p className="text-white text-sm md:text-base text-opacity-90 mb-4 md:w-4/5 leading-6 md:leading-7">{job.shortDescription}</p>
                                    <Link to={`/jobs/${job.id}/`} className="btn btn-light inline-block">
                                        Learn more
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </Wrapper>
    );
};
  
export default Jobs;